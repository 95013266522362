<template>
  <!-- Legend -->
  <div class="w-full flex flex-col gap-10">
    <legends-group />
    <div class="flex flex-wrap justify-between items-center gap-5">
      <div class="flex flex-wrap gap-5">
        <router-link to="/gerenciar-escala">
          <filled-button width="190px" text="Gerenciar escalas"></filled-button
        ></router-link>
        <ghost-button
          width="190px"
          @click="showCreateStationModal = true"
          text="Criar Plantão"
        ></ghost-button>

        <ghost-button @click="toggleExportModal" width="148px">
          <v-icon :icon="mdiMicrosoftExcel" :size="25" class="cursor-pointer" /> Baixar Excel
        </ghost-button>

        <ghost-button @click="modalStore.toggleModal(EModals.EXPORT_DASHBOARD_PDF)" width="148px">
          <v-icon :icon="mdiFilePdfBox" :size="25" class="cursor-pointer" /> Baixar PDF
        </ghost-button>
      </div>
    </div>
    <filters />
    <DashboardView
      id="dashboard"
      :weekData="listStations"
      :week-table-head="listStations?.tableHead || []"
      :selectedYearDay="selectedYearDay"
      @openDutyModal="handleUpdateStation"
    />
  </div>
  <update-station-modal
    v-if="stationSelected"
    :infos="stationSelected"
    :showModal="showDutyModal"
    @closeModal="showDutyModal = false"
  />
  <create-station-modal
    :showModal="showCreateStationModal"
    @closeModal="showCreateStationModal = false"
  />
  <export-excel-modal />
  <export-pdf-modal />
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { defineEmits } from 'vue'
import LegendsGroup from '@/components/LegendsGroup/index.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import GhostButton from '@/components/designSystem/Buttons/GhostButton/index.vue'
import { onMounted, ref } from 'vue'
import DashboardView from './components/DashboardView.vue'
import UpdateStationModal from './modals/updateStation/update.station.modal.vue'
import CreateStationModal from './modals/createStation/create.station.modal.vue'
import { useStationStore } from '@/pinia-store/useStationStore'
import { useDoctorStore } from '@/pinia-store/useDoctorStore'
import Filters from '@/components/Dashboard/Header/index.vue'
import { mdiFilePdfBox, mdiMicrosoftExcel } from '@mdi/js'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import ExportExcelModal from './modals/exportExcel/export.excel.modal.vue'
import ExportPdfModal from './modals/exportPdf/export.pdf.modal.vue'
import { StationToUpdate } from './modals/createStation/helpers'
export default {
  name: 'Dashboard',
  components: {
    LegendsGroup,
    FilledButton,
    GhostButton,
    DashboardView,
    Filters,
    UpdateStationModal,
    CreateStationModal,
    ExportExcelModal,
    ExportPdfModal
  },
  setup() {
    const selectedYearDay = ref({ day: -1, month: -1 })
    const showDutyModal = ref(false)
    const showCreateStationModal = ref(false)

    const stationSelected = ref<StationToUpdate>()
    defineEmits(['closeModal'])

    const modalStore = useModalStore()
    const toggleExportModal = () => {
      modalStore.toggleModal(EModals.EXPORT_DASHBOARD_EXCEL)
    }

    const stationStore = useStationStore()
    const { listStations } = storeToRefs(useStationStore())
    onMounted(async () => {
      stationStore.setDefaultQuery()
      await stationStore.getListStations()
    })

    const doctorStore = useDoctorStore()

    const handleUpdateStation = async (data: StationToUpdate) => {
      stationSelected.value = data
      await doctorStore.setDoctorsByCompany(data.companyId)
      showDutyModal.value = true
    }

    return {
      EModals,
      modalStore,
      selectedYearDay,
      showDutyModal,
      showCreateStationModal,
      listStations,
      stationSelected,
      handleUpdateStation,
      mdiMicrosoftExcel,
      mdiFilePdfBox,
      toggleExportModal
    }
  }
}
</script>
<style scoped>
.selectedYearDayBox {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 30;
}
</style>
<style>
:root {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-box-shadow: 0px 0px 16px rgba(128, 128, 128, 0.3);
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #9e9eb7 rgb(255, 255, 255);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #9e9eb7;
  border-radius: 20px;
  border: 3px solid rgb(255, 255, 255);
}
</style>
