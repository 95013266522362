<template>
  <div class="flex flex-col w-full bg-gray-50 rounded-lg p-2">
    <div class="cursor-pointer my-2 flex items-center gap-1 justify-between">
      <span class="font-bold">Horários</span>
      <edit-pencil-icon v-if="!toggleEdit" @click="toggleEdit = !toggleEdit" color="#2F7F91" />
      <div v-if="toggleEdit" class="flex gap-2 text-xs text-gray-500 font-bold">
        <span @click="saveEditMode">
          <v-icon :icon="mdiCheckCircleOutline" color="#22bb33" class="cursor-pointer" />
          Salvar Rascunho
        </span>
        <span @click="closeEditMode">
          <v-icon :icon="mdiCloseCircleOutline" color="#bb2124" class="cursor-pointer" />
          Descartar Rascunho
        </span>
      </div>
    </div>

    <div class="flex w-full">
      <div class="w-[15%]"></div>
      <div class="w-[30%]">
        <h1>Checkin</h1>
      </div>
      <div class="w-[30%]">
        <h1>Checkout</h1>
      </div>
      <div class="w-[15%]">
        <h1></h1>
      </div>

      <div class="w-[5%]"></div>
    </div>

    <v-radio-group v-model="isCheckMain">
      <div v-for="(info, i) of draftInformations" :key="i" class="flex w-full items-center mt-3">
        <div class="w-[15%] h-[44px] flex items-center text-xs font-bold text-gray-500">
          <v-radio
            :disabled="!toggleEdit"
            label=""
            name="checks"
            id="checks"
            color="primary"
            :value="i"
          ></v-radio>
        </div>
        <div class="w-[30%] h-[44px] flex items-start">
          <GenericInput
            :disabled="!toggleEdit || info.origin === 'DOCTOR'"
            v-model="info.checkout"
            name="checkin"
            density="compact"
            label=""
            v-maska="'##:##'"
            class="w-full"
            variant="outlined"
          >
          </GenericInput>
        </div>
        <div class="w-[30%] h-[44px] flex items-start">
          <GenericInput
            :disabled="!toggleEdit || info.origin === 'DOCTOR'"
            v-model="info.checkin"
            name="checkout"
            density="compact"
            label=""
            v-maska="'##:##'"
            class="w-full"
            variant="outlined"
          >
          </GenericInput>
        </div>
        <div class="w-[15%] h-[44px] flex items-center text-xs font-bold text-gray-500">
          {{ info.origin === 'DOCTOR' ? 'Doutor' : 'Web' }}
        </div>
        <button
          :disabled="!toggleEdit || info.origin === 'DOCTOR'"
          @click="removeCheck(i)"
          class="w-[5%] h-[44px] flex items-center"
        >
          <v-icon
            :icon="mdiDeleteOutline"
            :color="info.origin === 'DOCTOR' ? '#D3D3D3' : colorHex"
          />
        </button>
      </div>
    </v-radio-group>
    <button :disabled="!toggleEdit" @click="addNewCheck" class="my-2 flex items-center gap-1">
      <rounded-plus :color="colorHex" />
      <span class="font-bold text-sm" :class="colorText">Incluir mais horários</span>
    </button>
  </div>
</template>

<script lang="ts">
import {
  mdiInformation,
  mdiClipboardText,
  mdiDeleteOutline,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline
} from '@mdi/js'
import GenericInput from '@/components/designSystem/Inputs/GenericInput.vue'
import { onMounted } from 'vue'
import { format } from 'date-fns'
import RoundedPlus from '@/assets/icons/rounded-plus.vue'
import { ref } from 'vue'
import EditPencilIcon from '@/assets/icons/edit-pencil.vue'
import { computed } from 'vue'
import { CheckOrigin, HistoryDate } from '@/Network/Types/Requests/StationRequests'

export default {
  name: 'DoctorCheck',
  props: {
    infos: { type: Array<HistoryDate>, default: () => [] }
  },
  components: {
    GenericInput,
    RoundedPlus,
    EditPencilIcon
  },
  setup(props, ctx) {
    const draftInformations = ref<HistoryDate[]>([])

    const isCheckMain = ref<number | undefined>(0)
    const toggleEdit = ref<boolean>(false)
    const informations = ref<HistoryDate[]>([])

    const origins = ref<{ title: string; value: string }[]>([
      { title: 'Médico', value: CheckOrigin.DOCTOR },
      { title: 'WEB', value: CheckOrigin.WEB }
    ])

    onMounted(() => {
      informations.value = props.infos.map((v) => {
        if (v.isMain) isCheckMain.value = v.id
        return {
          id: v.id,
          checkin: format(new Date(v.checkin), 'HH:mm'),
          checkout: v.checkout ? format(new Date(v.checkout), 'HH:mm') : undefined,
          origin: v.origin ?? CheckOrigin.DOCTOR,
          isMain: v.isMain
        }
      })

      draftInformations.value = [...informations.value]
    })
    const colorHex = computed(() => (toggleEdit.value ? '#2F7F91' : '#D3D3D3'))
    const colorText = computed(() => (toggleEdit.value ? 'text-primary' : 'text-gray-300'))

    const addNewCheck = () => {
      draftInformations.value.push({
        checkin: '',
        checkout: '',
        origin: CheckOrigin.WEB,
        isMain: false
      })
    }

    const removeCheck = (pos: number) => {
      draftInformations.value = draftInformations.value.filter((_v, i) => i !== pos)
    }

    const closeEditMode = () => {
      toggleEdit.value = false
      draftInformations.value = informations.value
    }

    const saveEditMode = () => {
      let validForm = true
      draftInformations.value = draftInformations.value.map((c, i) => {
        if (!validateTime(c.checkin)) validForm = false
        return {
          ...c,
          isMain: i === isCheckMain.value
        }
      })

      if (!validForm) return
      toggleEdit.value = false

      ctx.emit('saveChecks', draftInformations.value)
    }

    const validateTime = (hours: string) => {
      const [hour, minutes] = hours.split(':')
      const isValidHours = Number(hour) >= 0 && Number(hour) <= 23
      const isValidMinutes = Number(minutes) >= 0 && Number(minutes) <= 59
      return isValidHours && isValidMinutes
    }

    return {
      isCheckMain,
      mdiInformation,
      mdiClipboardText,
      mdiDeleteOutline,
      informations,
      draftInformations,
      addNewCheck,
      removeCheck,
      closeEditMode,
      saveEditMode,
      toggleEdit,
      colorHex,
      colorText,
      mdiCheckCircleOutline,
      mdiCloseCircleOutline,
      origins
    }
  }
}
</script>
